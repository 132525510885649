import { useState } from "react";
import { Modal } from "react-bootstrap";
import { IoWarningOutline } from "react-icons/io5";
import styles from "../../login.module.css"

export default function ForgotPassword(params) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <span className="text-primary pointer float-end" onClick={handleShow}>
        Disclaimer
      </span>
      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        centered
        keyboard={false}
        size="lg"
        className="ps-0"
      >
        <Modal.Header closeButton>
          <Modal.Title>Disclaimer</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="mb-2">
            <IoWarningOutline className={`text-warning ${styles.disclaimerIcon}`} />
          </div>

          <p className={`mb-0 ${styles.disclaimerTextAlignment}`}>
            By signing in, you understand and agree that the content provided on MindBalance.net is for educational purposes only and is not intended as professional advice of any kind. The information available through this website should not be used as a substitute for consultation with professional advisors. We are not liable for any decisions or actions taken based on the information provided on this site. We encourage all users to seek appropriate professional advice before taking any action based on any information they may read here. MindBalance.net does not represent or warrant that the information provided is accurate, complete, or current. Use of the information and data contained on this website is at your sole risk.
          </p>
        </Modal.Body>
      </Modal>
    </>
  )
}