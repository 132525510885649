import { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useRouter } from "next/navigation";
import axios from "axios";
import Link from "next/link";
import ValidationError from "@components/ValidationError";


export default function ForgotPassword(params) {
  const [isLoading, setisLoading] = useState(false)
  const [email, setEmail] = useState();
  const router = useRouter()
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = () => {
    setResponseMessage("")
    setResponseStatus("")
    setValidationErrors([])
    event.preventDefault();
    setisLoading(true)

    let data = JSON.stringify({
      "email": email,
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.NEXT_PUBLIC_API_URL}auth/forgot-password`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        router.push("reset-password")
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 409) {
          setValidationErrors(error.response.data.errors);
          setResponseMessage(error.response.data.message);

        } else {
          setResponseMessage(error.response.data.message);
        }
        setResponseStatus(error.response.status);
      }).finally(() => {
        setisLoading(false)
      });
  }

  return (
    <>
      <a href="#" onClick={handleShow} className="p-0 bg-transparent border-0 text-primary text-decoration-none">
        Forgot Password?
      </a>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ValidationError
            status={responseStatus}
            message={responseMessage}
          />

          <Form.Group className="mb-3" controlId="email">
            <Form.Label>PLease enter your email:</Form.Label>
            <Form.Control
              type="email"
              required
              onChange={(event) => setEmail(event.target.value)}
              placeholder="name@example.com"
              isInvalid={!!validationErrors.email}
            />
            {validationErrors.email && (
              <ValidationError
                status={responseStatus}
                message={validationErrors.email[0]}
              />
            )}

          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" onClick={handleSubmit} className={`py-2`}>
            Submit
            {
              isLoading &&
              <Spinner className="ms-1" size="sm" animation="border" variant="light" />
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}