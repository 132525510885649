"use client"
import axios from "axios";
import { useRouter, useSearchParams } from "next/navigation";
import { useState } from "react";
import { Alert, Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import styles from "./login.module.css"
import ValidationError from "@components/ValidationError";
import { useDispatch } from "react-redux";
import ForgotPassword from "./Components/ForgotPassword";
import Link from "next/link";
import { setAccessToken, setUser } from "@store/userLoginSlice";
import PasswordField from "@components/PasswordField";
import Disclaimer from "./Components/Disclaimer";

export default function Login() {

  const dispatch = useDispatch();

  const router = useRouter();
  const searchParams = useSearchParams()

  const message = searchParams.get('message') || null;

  const [isLoading, setIsLoading] = useState(false)
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState();
  const [validationErrors, setValidationErrors] = useState({});

  const [state, setState] = useState({
    emailOrUsername: "",
    password: ""
  })

  const handleChange = (event) => {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value,
    });
  };

  const handleSubmit = async (event) => {

    event.preventDefault();

    setResponseMessage("")
    setResponseStatus("")
    setValidationErrors([])

    setIsLoading(true)

    let data = JSON.stringify({
      "emailOrUsername": state.emailOrUsername,
      "password": state.password
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.NEXT_PUBLIC_API_URL}auth/login`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    await axios.request(config)
      .then((response) => {

        dispatch(setAccessToken(response.data));
        dispatch(setUser({
          user: response.data.user
        }));

        if (response.data.user.role === "admin") {
          return router.push('/admin/dashboard');
        } else {

          if (response.data.user.role === "professional") {
            return router.push('/professional/dashboard');
          } else if (response.data.user.role === "consumer" && !response.data.user.isAssessmentcompleted) {
            return router.push('/assessment/self');
          } else {
            router.push('/assessment');
          }
        }

      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 409) {
          setValidationErrors(error.response.data.errors);
          setResponseMessage(error.response.data.message);
        } else {
          setResponseMessage(error.response.data.message);
        }
        setResponseStatus(error.response.status);

      }).finally(() => setIsLoading(false));
  };

  return (
    <>

      {/* <ProgressBarWithNodes /> */}

      <Container className="mh-75 d-flex align-items-center">
        <Row className="w-100 m-0 d-flex align-items-center">
          <Col xs={{ span: 12 }} sm={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xxl={{ span: 4, offset: 4 }}>
            <div className="mb-4">
              <h4 className="fw-semibold text-center">Mind Balance is your ticket to a happier, more fulfilled life!</h4>
            </div>

            <Form onSubmit={handleSubmit}>
              <ValidationError
                status={responseStatus}
                message={responseMessage}
              />
              {
                message &&
                <Alert variant="success">
                  {message}
                </Alert>
              }
              <Form.Group className="mb-3" controlId="email">
                <Form.Control
                  type="text"
                  required
                  className={`${styles.formControl} py-2 `}
                  name="emailOrUsername"
                  onChange={handleChange}
                  placeholder="Enter email or username"
                  isInvalid={!!validationErrors.email}
                />
                {validationErrors.email && (
                  <ValidationError
                    status={responseStatus}
                    message={validationErrors.email[0]}
                  />
                )}
              </Form.Group>

              <div className="mb-3">
                <PasswordField
                  name="password"
                  handleChange={handleChange}
                  validationErrors={validationErrors}
                />
                {validationErrors.password && (
                  <ValidationError
                    status={responseStatus}
                    message={validationErrors.password[0]}
                  />
                )}
              </div>

              <Button type="submit" variant="primary" className={`w-100 py-2`}>
                SIGN IN
                {
                  isLoading &&
                  <Spinner className="ms-1" size="sm" animation="border" variant="light" />
                }
              </Button>
            </Form>

            <div className="d-flex justify-content-between mt-3 " >
              <Link href="/signup" className="text-decoration-none">Sign up </Link>
              <ForgotPassword />
            </div>
            <Disclaimer />
          </Col>
        </Row>
      </Container>
    </>
  )
}
