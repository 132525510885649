import { useState } from "react";
import { Alert, Form } from "react-bootstrap";

export default function ValidationError(props) {
  const [show, setShow] = useState(true);
  return (
    <>
      {props.status === 500 ||
        props.status === 401 ||
        props.status === 403 ||
        props.status === 406 ||
        props.status === 404 ? (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          {props.message}
        </Alert>
      ) : (
        ""
      )}

      {/* Form input field errors */}
      {props.status === 400 || props.status === "400" || props.status === 409 || props.status === "409" ? (
        <Form.Control.Feedback type="invalid" style={{ fontSize: "13px" }} className="m-0 text-start w-100 d-block">
          {props.message}
        </Form.Control.Feedback>
      ) : (
        ""
      )}

      {/* Success Message */}
      {props.status === 200 || props.status === "200" || props.status === 201 || props.status === "201" ? (
        <div>
          {show ? (
            <Alert variant="success" onClose={() => setShow(false)} dismissible>
              {props.message}
            </Alert>
          ) : null}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
