import { Form } from "react-bootstrap";
import ValidationError from "../ValidationError";
import { useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import styles from "./PasswordField.module.css"

export default function PasswordField({ name, handleChange, validationErrors, controlId }) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  return (

    <Form.Group className={`${styles.formControl} form-control p-0`} controlId={controlId}>
      <div className="align-items-center d-flex">

        <Form.Control
          type={isPasswordVisible ? "text" : "password"}
          className={`border-0 py-2`}
          name={name}
          onChange={handleChange}
          placeholder="********"
          autoComplete="true"
          required
        />
        {
          isPasswordVisible ?
            <FaRegEye className="fs-4 me-3 pointer mianTextColor" onClick={() => setIsPasswordVisible(false)} />
            :
            <FaRegEyeSlash className="fs-4 me-3 pointer mianTextColor" onClick={() => setIsPasswordVisible(true)} />
        }
      </div>
    </Form.Group>

  )
}